import React, {
    useEffect, useRef, useState,
} from 'react';
import useCart from './hook/useCart';
import CartItem from './CartItem/CartItem';
import {
    Empty, Labels, Label, CartItemsContainer,
    WarningMessage, EmptyMessage, LabelsFixed,
    StyledWarningMessage, StyledLink,
} from './CartStyles';
import escapeDiacritics from '../../tools/escapeDiacritics';
import { getNoAuth, get } from '../../api/Api';
import { isFirstStepValid } from '../pages/Cart/validation';

export default function Cart({
    className, handleTotalPriceChange = () => { }, isInvalid = false,
    productsDiscounts = [], readOnly, discount, setIsStepValid = () => { },
}) {
    const {
        addItem,
        decreaseQuantityOfItem,
        changeQuantityOfItem,
        removeItem,
        getItems,
        itemsCount,
        changePriceOfItem,
        changeStockTextOfItem,
        changePreorderTextOfItem,
        changePriceBeforeOfItem,
        changeIsVisibleOfItem,
        changeIsBuyableOfItem,
        changeMaxQuantityPerOrder,
    } = useCart();
    const [items, setItems] = useState(getItems());
    const [scrollTop, setScrollTop] = useState(0);
    const cartRef = useRef(null);
    const [cartHeight, setCartHeight] = useState(0);

    const updateItems = () => {
        setItems(getItems());
        handleTotalPriceChange();
    };

    const handleIncreaseClick = (item, event) => {
        event.preventDefault();

        window.gtag('event', 'add_to_cart', {
            items: [{
                id: item.sku,
                name: escapeDiacritics(item.name),
                quantity: 1,
                price: item.price,
            }],
        });

        window.fbq('track', 'AddToCart', {
            content_name: escapeDiacritics(item.name),
            content_type: 'product',
            content_ids: [item.sku ?? item.id],
            value: item.price,
            currency: 'PLN',
        });

        addItem(item);
        updateItems();
    };

    const handleDecreaseClick = (item, event) => {
        event.preventDefault();
        decreaseQuantityOfItem(item);
        updateItems();
    };

    const handleRemoveClick = (item, event) => {
        event.preventDefault();

        window.gtag('event', 'remove_from_cart', {
            items: [{
                id: item.sku,
                name: escapeDiacritics(item.name),
                quantity: item.quantity,
                price: item.price,
            }],
        });
        removeItem(item);
        updateItems();
    };

    const handleQuantityChange = (item) => {
        changeQuantityOfItem(item);
        updateItems();
    };

    const handlePriceChange = (item) => {
        changePriceOfItem(item);
        updateItems();
    };

    const handlePriceBeforeChange = (item) => {
        changePriceBeforeOfItem(item);
        updateItems();
    };

    const handleStockTextChange = (item) => {
        changeStockTextOfItem(item);
        updateItems();
    };

    const handlePreorderTextChange = (item) => {
        changePreorderTextOfItem(item);
        updateItems();
    };

    const handleMaxQuantityPerItemChange = (item) => {
        changeMaxQuantityPerOrder(item);
        updateItems();
    };

    const handleIsVisibleChange = (item) => {
        changeIsVisibleOfItem(item);
        updateItems();
    };

    const handleIsBuyableChange = (item) => {
        changeIsBuyableOfItem(item);
        updateItems();
    };

    useEffect(() => {
        handleTotalPriceChange();
        // eslint-disable-next-line
    }, [discount]);

    useEffect(() => {
        updateItems();
        setCartHeight(cartRef.current?.clientHeight);
        // eslint-disable-next-line
    }, [itemsCount]);

    useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    useEffect(() => {
        const onFocus = () => {
            items.forEach((item) => {
                getNoAuth(`products/slug/${item.url.split('/')[2]}`).then((response) => {
                    if (typeof response === 'object' && response.id !== undefined) {
                        if (item.isVisible !== response.is_visible) {
                            item.isVisible = response.is_visible;
                            handleIsVisibleChange(item);
                        }

                        if (item.isBuyable !== response.is_buyable) {
                            item.isBuyable = response.is_buyable;
                            handleIsBuyableChange(item);
                        }

                        if (item.price !== response.price) {
                            item.price = response.price;
                            handlePriceChange(item);
                        }

                        if (response.price_before && item.priceBefore !== response.price_before) {
                            item.priceBefore = response.price_before;
                            handlePriceBeforeChange(item);
                        }

                        if (item.stockText !== response.translations[0].stock_text) {
                            item.stockText = response.translations[0].stock_text;
                            handleStockTextChange(item);
                        }

                        if (item.preorderText !== response.translations[0].preorder_text) {
                            item.preorderText = response.translations[0].preorder_text;
                            handlePreorderTextChange(item);
                        }

                        if (item.maxQuantityPerOrder !== response.max_quantity_per_order) {
                            item.maxQuantityPerOrder = response.max_quantity_per_order;
                            handleMaxQuantityPerItemChange(item);
                        }
                    }
                });

                if (item.offerHash) {
                    get(`offers/${item.offerHash}/is-cart-active`).then((response) => {
                        if (response !== true) {
                            item.isBuyable = false;
                            handleIsBuyableChange(item);
                        }
                    });
                }
            });
            setIsStepValid('1', isFirstStepValid(itemsCount, items));
        };

        window.addEventListener('focus', onFocus);
        onFocus();

        return () => {
            window.removeEventListener('focus', onFocus);
        };
        // eslint-disable-next-line
    }, []);
    const isItemPriceEqualToDiscountedPrice = (item) => {
        if (!productsDiscounts[item.id]) {
            return true;
        }

        return +item.price === +productsDiscounts[item.id];
    };

    const areAnyItemsWithDiscount = () => !items.every(isItemPriceEqualToDiscountedPrice);

    const LabelsContent = (
        <>
            <Label>Cena za sztukę</Label>
            <Label className="quantity" readOnly={readOnly}>Ilość</Label>
            {productsDiscounts.length > 0 && areAnyItemsWithDiscount()
                && <Label className="discount">Kod rabatowy</Label>}
            <Label className="totalValue">Wartość</Label>
            {!readOnly && <Label className="remove">Usuń</Label>}
        </>
    );

    return (
        <div className={className}>
            {(items && items.length > 0) ? (
                <>
                    <CartItemsContainer scrollTop={scrollTop} ref={cartRef}>
                        <Labels readOnly={readOnly} showDiscount={discount} scrollTop={scrollTop}>
                            {LabelsContent}
                        </Labels>
                        {scrollTop > 66 && scrollTop < (cartHeight) && (
                            <LabelsFixed readOnly={readOnly} showDiscount={discount}>
                                {LabelsContent}
                            </LabelsFixed>
                        )}
                        {items.map((item, key) => (
                            <CartItem
                                displayDiscount={productsDiscounts.length > 0 && areAnyItemsWithDiscount()}
                                productDiscount={productsDiscounts[item.id]}
                                key={key}
                                item={item}
                                decreaseClickHandler={handleDecreaseClick}
                                increaseClickHandler={handleIncreaseClick}
                                handleQuantityChange={handleQuantityChange}
                                removeClickHandler={handleRemoveClick}
                                readOnly={readOnly}
                            />
                        ))}
                    </CartItemsContainer>
                    {isInvalid
                        && (
                            <StyledWarningMessage>
                                Usuń niedostępne produkty z koszyka, aby przejść dalej.
                            </StyledWarningMessage>
                        )}
                </>
            ) : (
                <Empty>
                    <EmptyMessage>
                        Nie dodałeś jeszcze produktów do koszyka, możesz to zrobić w sekcji:
                        <div>
                            <StyledLink to="/dla-przedszkoli">Dla przedszkoli</StyledLink>
                            |
                            <StyledLink to="/dla-szkol">Dla szkół</StyledLink>
                            |
                            <StyledLink to="/szkolenia">Szkolenia</StyledLink>
                        </div>
                    </EmptyMessage>
                    {isInvalid
                        && (
                            <WarningMessage>
                                Wybierz co najmniej jeden produkt do zamówienia.
                            </WarningMessage>
                        )}
                </Empty>
            )}
        </div>
    );
}
